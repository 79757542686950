exports.components = {
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-content-blog-achievement-100-k-bineroo-grids-completed-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/builds/DARGIL/dargil.com/content/blog/achievement-100k-bineroo-grids-completed/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-content-blog-achievement-100-k-bineroo-grids-completed-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-content-blog-bineroo-1-10-released-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/builds/DARGIL/dargil.com/content/blog/bineroo-1-10-released/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-content-blog-bineroo-1-10-released-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-content-blog-bineroo-1-12-released-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/builds/DARGIL/dargil.com/content/blog/bineroo-1-12-released/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-content-blog-bineroo-1-12-released-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-content-blog-bineroo-1-14-released-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/builds/DARGIL/dargil.com/content/blog/bineroo-1-14-released/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-content-blog-bineroo-1-14-released-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-content-blog-bineroo-1-3-released-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/builds/DARGIL/dargil.com/content/blog/bineroo-1-3-released/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-content-blog-bineroo-1-3-released-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-content-blog-bineroo-1-8-released-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/builds/DARGIL/dargil.com/content/blog/bineroo-1-8-released/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-content-blog-bineroo-1-8-released-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-content-blog-bineroo-1-9-released-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/builds/DARGIL/dargil.com/content/blog/bineroo-1-9-released/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-content-blog-bineroo-1-9-released-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-content-blog-bineroo-grid-explained-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/builds/DARGIL/dargil.com/content/blog/bineroo-grid-explained/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-content-blog-bineroo-grid-explained-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-content-blog-i-played-my-own-game-for-100-consecutive-days-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/builds/DARGIL/dargil.com/content/blog/i-played-my-own-game-for-100-consecutive-days/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-content-blog-i-played-my-own-game-for-100-consecutive-days-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-content-blog-it-all-started-with-a-mosquito-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/builds/DARGIL/dargil.com/content/blog/it-all-started-with-a-mosquito/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-content-blog-it-all-started-with-a-mosquito-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-content-games-bineroo-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/builds/DARGIL/dargil.com/content/games/bineroo/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-content-games-bineroo-index-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-policy-js": () => import("./../../../src/pages/policy.js" /* webpackChunkName: "component---src-pages-policy-js" */)
}

